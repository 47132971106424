import React, { Fragment } from 'react';

import { Section, SectionContent, SectionHeader } from 'src/shared/components';

import ContactForm from './components/ContactForm/ContactForm';
import ContactInfoList from './components/ContactInfoList/ContactInfoList';
import styles from './ContactSection.module.scss';

const ContactSection = () => (
	<Section name="contact" animation="fadeIn" classes={{ section: styles.section }}>
		<SectionHeader
			title="Contacto"
			description="Cuéntanos sobre tu proyecto y comencemos a crear nuevas soluciones juntos."
		/>
		<SectionContent componentRoot={Fragment} animation="fadeInUp">
			<ContactForm />
			<ContactInfoList />
		</SectionContent>
	</Section>
);

export default ContactSection;
