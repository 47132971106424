import React, { Fragment } from 'react';

import { Section, SectionContent, SectionHeader } from 'src/shared/components';

import TechnologiesCarousel from './components/TechnologiesCarousel/TechnologiesCarousel';
import styles from './TechnologiesSection.module.scss';

const TechnologiesSection = () => (
	<Section name="technologies" animation="fadeIn" classes={{ section: styles.section }}>
		<SectionHeader
			title="Tecnologías"
			description={
				<Fragment>
					¡La innovación es lo que nos motiva!
					<br />
					Por eso desarrollamos nuestras soluciones con los stacks tecnológicos más populares y efectivos para acompañar
					la transformación digital de tu negocio.
				</Fragment>
			}
		/>
		<SectionContent animation="fadeInUp">
			<TechnologiesCarousel />
		</SectionContent>
	</Section>
);

export default TechnologiesSection;
