import React from 'react';

import { ButtonLinkOutstanding } from 'src/shared/components';

import styles from './HomeButtonsList.module.scss';

const HomeButtonsList = () => (
	<div className={styles.actions}>
		<ButtonLinkOutstanding id="get_started_button" href="#about">
			Iniciemos
		</ButtonLinkOutstanding>
		<ButtonLinkOutstanding id="services_button" variant="outlined" href="#services">
			Qué Ofrecemos
		</ButtonLinkOutstanding>
	</div>
);

export default HomeButtonsList;
