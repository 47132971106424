import React from 'react';
import classNames from 'classnames';

import styles from './MorphPhrase.module.scss';

type MorphPhraseProps = {
	active: boolean;
	animation: string;
	phrase: string;
};

const MorphPhrase = (props: MorphPhraseProps) => {
	const classesObject = {
		[styles.morphPhraseHidden]: true,
		[styles.morphPhraseAnimated]: props.active,
		animated: props.active
	};

	//@ts-ignore
	classesObject[props.animation] = props.active;
	const classes = classNames(classesObject);

	return <span className={classes}>{props.phrase}</span>;
};

export default MorphPhrase;
