import React from 'react';
import { Col, Row } from 'react-bootstrap';

import ContactInfoItem from './components/ContactInfoItem/ContactInfoItem';
import styles from './ContactInfoList.module.scss';

const ContactInfoList = () => (
	<Row className={styles.list}>
		<Col md={6} className={styles.separatorBorder}>
			<ContactInfoItem
				title="teléfono"
				caption="+1 (786) 927-7710"
				link="tel:+17869277710"
				linkId="contact_phone_link"
				icon="phone"
				iconId="contact_phone_icon"
			></ContactInfoItem>
		</Col>
		<Col md={6}>
			<ContactInfoItem
				title="email"
				caption="info@cymetsolutions.com"
				link="mailto:info@cymetsolutions.com"
				linkId="contact_email_link"
				icon="envelope"
				iconId="contact_email_icon"
			></ContactInfoItem>
		</Col>
	</Row>
);

export default ContactInfoList;
