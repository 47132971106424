import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { Animated } from 'react-animated-css';
import { animateScroll as scroll } from 'react-scroll';

import { propsSmoothScrollLastMenuLinks } from 'src/shared/constants';

import styles from './BackToTopFloatButton.module.scss';

const BackToTopFloatButton = () => {
	const [showOnScroll, setShowOnScroll] = useState(false);

	useScrollPosition(
		({ currPos }) => {
			const isShow = currPos.y > 100;
			if (isShow !== showOnScroll) setShowOnScroll(isShow);
		},
		[showOnScroll],
		undefined,
		true
	);

	const goBackToTop = () => {
		scroll.scrollToTop({
			smooth: propsSmoothScrollLastMenuLinks.smooth,
			duration: propsSmoothScrollLastMenuLinks.duration,
			delay: propsSmoothScrollLastMenuLinks.delay
		});
	};

	return (
		<Animated
			animationIn="fadeIn"
			animationInDuration={800}
			animationOut="fadeOut"
			animationOutDuration={800}
			isVisible={showOnScroll}
			className={styles.container}
		>
			<a id="back_to_top_link" className={styles.button} onClick={goBackToTop}>
				<FontAwesomeIcon icon={'chevron-up'} className={styles.icon} />
			</a>
		</Animated>
	);
};

export default BackToTopFloatButton;
