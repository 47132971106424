import React from 'react';

import { MainLayout } from 'src/layouts';

const Services = () => {
	return (
		<MainLayout>
			<h2>Cooming Soon!</h2>
		</MainLayout>
	);
};

export default Services;
