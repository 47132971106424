import { getDatabase, onValue, ref } from 'firebase/database';

export const getCurrentYear = () => {
	return new Promise<number>((resolve, reject) => {
		try {
			onValue(
				ref(getDatabase(), '/.info/serverTimeOffset'),
				(snapshot) => {
					try {
						const currentYear = new Date(snapshot.val() + Date.now()).getFullYear();
						resolve(currentYear);
					} catch (error) {
						reject(error);
					}
				},
				{
					onlyOnce: true
				}
			);
		} catch (error) {
			reject(error);
		}
	});
};
