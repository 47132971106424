import React from 'react';

import { MainLayout } from 'src/layouts';

import {
	AboutSection,
	ContactSection,
	HomeSection,
	PhraseSection,
	ServicesSection,
	TechnologiesSection
} from './components';

const Index = () => {
	return (
		<MainLayout homeComponent={HomeSection}>
			<AboutSection />
			<PhraseSection />
			<ServicesSection />
			<TechnologiesSection />
			<ContactSection />
		</MainLayout>
	);
};

export default Index;
