import { createSlice } from '@reduxjs/toolkit';

import { ServerState } from 'src/shared/models';

import { fetchCurrentYear } from './serverThunk';

const initialState: ServerState = {
	currentYear: null
};

export const serverSlice = createSlice({
	name: 'server',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchCurrentYear.fulfilled, (state, action) => {
			state.currentYear = action.payload;
		});
	}
});

export default serverSlice.reducer;
