import React, { Fragment } from 'react';

import AboutPillarItem from './components/AboutPillarItem/AboutPillarItem';

const AboutPillarList = () => (
	<Fragment>
		<AboutPillarItem title="Nuestra Misión" icon="flag-checkered">
			Nuestro objetivo es aportar nuestro conocimiento y creatividad para{' '}
			<b>acelerar la transformación digital global</b>, centrándonos en acompañar a las empresas de todo el mundo a que
			aprovechen las últimas y más avanzadas tecnologías para mejorar sus procesos de negocios y de este modo contribuir
			con su crecimiento.
		</AboutPillarItem>
		<AboutPillarItem title="Nuestra Visión" icon="binoculars" animationDelay={1000}>
			Orientamos nuestros esfuerzos en entender cuidadosamente las necesidades de nuestros clientes, y nos apalancamos
			en las tecnologías de vanguardia y las metodologías ágiles para desarrollar las soluciones digitales que mejor se
			adaptan para <b>alcanzar sus desafíos de negocios</b>.
		</AboutPillarItem>
	</Fragment>
);

export default AboutPillarList;
