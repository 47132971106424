import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import logoCircular from 'src/assets/images/logo-circular.png';

import styles from './AboutLogo.module.scss';

const AboutLogo = () => (
	<ScrollAnimation animateIn="zoomIn" animateOnce={true} offset={0} delay={1500}>
		<ScrollAnimation animateIn="pulse" delay={2000} initiallyVisible={true} className={styles.aboutImg}>
			<img src={logoCircular} alt="Logo Circular Cymet Solutions" />
		</ScrollAnimation>
	</ScrollAnimation>
);

export default AboutLogo;
