import { createSlice } from '@reduxjs/toolkit';

import { ContactFormState } from 'src/shared/models';

import { registerContactForm } from './contactFormThunk';

const initialState: ContactFormState = {
	isLoading: false,
	registerSuccessful: null,
	registerFailed: null
};

export const contactFormSlice = createSlice({
	name: 'contactForm',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(registerContactForm.pending, (state) => {
			state.isLoading = true;
			state.registerSuccessful = null;
			state.registerFailed = null;
		});
		builder.addCase(registerContactForm.fulfilled, (state) => {
			state.isLoading = false;
			state.registerSuccessful = true;
			state.registerFailed = false;
		});
		builder.addCase(registerContactForm.rejected, (state) => {
			state.isLoading = false;
			state.registerFailed = true;
			state.registerSuccessful = false;
		});
	}
});

export default contactFormSlice.reducer;
