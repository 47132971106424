import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { GTM_EVENTS } from 'src/shared/constants';
import { gtmUtils } from 'src/shared/toolbox';
import { registerContactForm } from 'src/store/contactForm';

import ContactFormAlert from './components/ContactFormAlert/ContactFormAlert';
import ContactFormButtonLoading from './components/ContactFormButtonLoading/ContactFormButtonLoading';
import schema from './ContactFormSchemaValidation';
import styles from './ContactForm.module.scss';

const ContactForm = () => {
	const dispatch = useDispatch<AppDispatch>();
	const [showAlertSucess, setShowAlertSucess] = useState(false);
	const [showAlertError, setShowAlertError] = useState(false);
	const {
		register,
		formState: { errors },
		handleSubmit,
		reset: resetForm
	} = useForm({
		mode: 'onTouched',
		resolver: yupResolver(schema)
	});

	const { isLoading, registerSuccessful, registerFailed } = useSelector((state: RootState) => state.contactForm);

	const onSubmit = (data: any) => {
		dispatch(registerContactForm(data));
		gtmUtils.pushDataLayerGTM(GTM_EVENTS.CONTACT_FORM_SUBMIT);
	};

	useEffect(() => {
		if (registerSuccessful) {
			setShowAlertSucess(true);
			resetForm();
			gtmUtils.pushDataLayerGTM(GTM_EVENTS.CONTACT_FORM_SUBMIT_SUCCESSFUL);
		}
	}, [registerSuccessful, resetForm]);

	useEffect(() => {
		if (registerFailed) {
			setShowAlertError(true);
			gtmUtils.pushDataLayerGTM(GTM_EVENTS.CONTACT_FORM_SUBMIT_FAILED);
		}
	}, [registerFailed]);

	return (
		<Row style={{ justifyContent: 'center' }}>
			<Col md={8}>
				<ContactFormAlert
					variant="success"
					title="Tu mensaje ha sido enviado."
					body="Te responderemos lo antes posible."
					footer="¡Gracias por contactarnos!"
					show={(showAlertSucess && registerSuccessful) as boolean}
					onClose={() => setShowAlertSucess(false)}
				/>
				<ContactFormAlert
					variant="danger"
					title="¡Ups! Ocurrió un error."
					body="Por favor intente de nuevo en unos minutos."
					footer={
						<>
							Si el problema persiste, por favor pongase en contacto con nosotros a través de nuestro email:{' '}
							<Alert.Link
								id="contact_form_email_alert_error_link"
								href="mailto:info@cymetsolutions.com"
								className={styles.formEmailAlertErrorLink}
							>
								info@cymetsolutions.com
							</Alert.Link>
							.
						</>
					}
					show={(showAlertError && registerFailed) as boolean}
					onClose={() => setShowAlertError(false)}
				/>
				<Form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
					<Row className={styles.formRow}>
						<FloatingLabel as={Col} md={6} controlId="floatingInputName" label="Tu nombre">
							<Form.Control type="text" placeholder="Tu nombre" {...register('name')} isInvalid={!!errors.name} />
							<Form.Control.Feedback type="invalid">{errors.name?.message as string}</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel as={Col} md={6} controlId="floatingInputLastName" label="Tu apellido">
							<Form.Control
								type="text"
								placeholder="Tu apellido"
								{...register('lastName')}
								isInvalid={!!errors.lastName}
							/>
							<Form.Control.Feedback type="invalid">{errors.lastName?.message as string}</Form.Control.Feedback>
						</FloatingLabel>
					</Row>
					<Row className={styles.formRow}>
						<FloatingLabel as={Col} md={6} controlId="floatingInputEmail" label="Tu email">
							<Form.Control type="text" placeholder="Tu email" {...register('email')} isInvalid={!!errors.email} />
							<Form.Control.Feedback type="invalid">{errors.email?.message as string}</Form.Control.Feedback>
						</FloatingLabel>

						<FloatingLabel as={Col} md={6} controlId="floatingInputCompany" label="Tu empresa">
							<Form.Control
								type="text"
								placeholder="Tu empresa"
								{...register('company')}
								isInvalid={!!errors.company}
							/>
							<Form.Control.Feedback type="invalid">{errors.company?.message as string}</Form.Control.Feedback>
						</FloatingLabel>
					</Row>
					<Row className={styles.formRow}>
						<FloatingLabel as={Col} md={12} controlId="floatingTextAreaMssage" label="Déjanos tu mensaje">
							<Form.Control
								as="textarea"
								placeholder="Déjanos tu mensaje"
								style={{ height: '134px' }}
								{...register('message')}
								isInvalid={!!errors.message}
							/>
							<Form.Control.Feedback type="invalid">{errors.message?.message as string}</Form.Control.Feedback>
						</FloatingLabel>
					</Row>
					<Row className="justify-content-center">
						<Col xs={6} md={3} className="d-grid gap-2">
							<ContactFormButtonLoading
								id="contact_form_button"
								variant="secondary"
								textLoading="Enviando..."
								isLoading={isLoading}
							>
								Enviar
							</ContactFormButtonLoading>
						</Col>
					</Row>
				</Form>
			</Col>
		</Row>
	);
};

export default ContactForm;
