import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col } from 'react-bootstrap';

import { Section, SectionContent } from 'src/shared/components';

import styles from './PhraseSection.module.scss';

const PhraseSection = () => {
	return (
		<Section name="phrase" classes={{ section: styles.section }}>
			<SectionContent>
				<Col md={10}>
					<ScrollAnimation animateIn="fadeInDown" animateOut="fadeOutUp" delay={200}>
						<p className={styles.text}>Somos Cymet Solutions</p>
					</ScrollAnimation>
					<ScrollAnimation animateIn="fadeInUp" animateOut="fadeOutDown" delay={400}>
						<h3 className={styles.title}>
							Aportamos nuestro conocimiento y creatividad para acelerar la transformación digital global.
						</h3>
					</ScrollAnimation>
				</Col>
			</SectionContent>
		</Section>
	);
};

export default PhraseSection;
