import React, { useEffect, useRef, useState } from 'react';

import MorphPhrase from './components/MorphPhrase/MorphPhrase';

type MorphextProps = {
	animation?: string;
	speed?: number;
	phrases: string[];
};

// @ts-ignore
const Morphext = ({ animation = 'bounceIn', speed = 3000, phrases }: MorphextProps) => {
	const [indexState, setIndexState] = useState(0);
	const indexRef = useRef(0); //To keep the index into the SetInterval
	const intervals: NodeJS.Timer[] = [];

	useEffect(() => {
		if (phrases.length > 1) {
			startInterval(speed);
		}

		return () => {
			intervals.map((x) => clearInterval(x));
		};
	}, []);

	const startInterval = (milliseconds: number) => {
		intervals.push(
			setInterval(() => {
				indexRef.current += 1;

				if (indexRef.current === phrases.length) {
					indexRef.current = 0;
				}

				setIndexState(indexRef.current);
			}, milliseconds)
		);
	};

	const renderPhrases = phrases.map((text, id) => {
		return <MorphPhrase key={id} phrase={text} animation={animation} active={id === indexState} />;
	});

	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	return phrases && !!phrases.length && <div style={{ display: 'inline-block' }}>{renderPhrases}</div>;
};

export default Morphext;
