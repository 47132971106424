import * as yup from 'yup';

export const schema = yup.object().shape({
	name: yup.string().required('Por favor ingresa tu nombre').max(50, 'Por favor ingresa como máximo 50 caracteres'),
	lastName: yup
		.string()
		.required('Por favor ingresa tu apellido')
		.max(50, 'Por favor ingresa como máximo 50 caracteres'),
	email: yup
		.string()
		.required('Por favor ingresa tu email')
		.email('Por favor ingresa un email válido')
		.max(50, 'Por favor ingresa como máximo 50 caracteres'),
	company: yup.string().required('Por favor ingresa tu empresa').max(50, 'Por favor ingresa como máximo 50 caracteres'),
	message: yup
		.string()
		.required('Por favor escriba un breve mensaje')
		.max(300, 'Por favor ingresa como máximo 300 caracteres')
});

export default schema;
