import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCurrentYear } from 'src/services/server/serverService';

export const fetchCurrentYear = createAsyncThunk('server/fetchCurrentYear', async () => {
	try {
		const response = await getCurrentYear();
		return response;
	} catch (error) {
		console.error('error calling to services server.getCurrentYear:', error);
		throw error;
	}
});
