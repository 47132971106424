import { createAsyncThunk } from '@reduxjs/toolkit';
import { serverTimestamp } from 'firebase/database';

import { addContactForm } from 'src/services/contactForm/contactFormService';
import { ContactForm } from 'src/shared/models';

export const registerContactForm = createAsyncThunk(
	'contactForm/registerContactForm',
	async (formData: ContactForm) => {
		try {
			formData.dateCreated = serverTimestamp();
			const response = await addContactForm(formData);
			return response;
		} catch (error) {
			console.error('error calling to services to create contact form', error);
			throw error;
		}
	}
);
