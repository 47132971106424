import React from 'react';

import { Section, SectionContent } from 'src/shared/components';

import HomeButtonsList from './components/HomeButtonsList/HomeButtonsList';
import HomeLogo from './components/HomeLogo/HomeLogo';
import HomeText from './components/HomeText/HomeText';
import styles from './HomeSection.module.scss';

const HomeSection = () => (
	<Section name="home" componentContainer={'div'} classes={{ section: styles.section, container: styles.container }}>
		<SectionContent componentRoot={'div'}>
			<HomeLogo />
			<HomeText />
			<HomeButtonsList />
		</SectionContent>
	</Section>
);

export default HomeSection;
