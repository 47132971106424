import TagManager from 'react-gtm-module';

export class gtmUtils {
	static pushDataLayerGTM(eventName: string): void;
	static pushDataLayerGTM(data: Record<string, string>): void;
	static pushDataLayerGTM(data: string | Record<string, string>) {
		if (typeof data == 'string') {
			TagManager.dataLayer({
				dataLayer: {
					event: data
				}
			});
		} else {
			TagManager.dataLayer({ dataLayer: data });
		}
	}
}
