import axios from 'axios';

import { ContactForm } from 'src/shared/models';

const CONTACT_API_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL + '/contacto.json';

//TODO: Pending define type to response, when it needs to be used
export const addContactForm = async (dataForm: ContactForm) => {
	const { data } = await axios.post(CONTACT_API_URL, dataForm);
	return data;
};
