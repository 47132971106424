import React, { Fragment } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

import AboutPillarList from './components/AboutPillarList/AboutPillarList';
import styles from './AboutPanel.module.scss';

const AboutPanel = () => (
	<Fragment>
		<ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={0}>
			<h2 className={styles.title}>Brindamos soluciones de software para materializar tus desafíos.</h2>
			<p className={styles.text}>
				Somos una empresa especializada en el desarrollo de software con una creciente expansión en el mercado, nuestro
				trabajo se enfoca en potenciar con soluciones digitales la productividad empresarial mediante una{' '}
				<b>perfecta integración de tecnología, negocios y diseño</b>.<br></br>
				<br></br>En Cymet Solutions tomamos como principal premisa el <b>desarrollo ágil e innovador</b> para alcanzar
				las mejores soluciones de software personalizadas.
			</p>
		</ScrollAnimation>
		<AboutPillarList />
	</Fragment>
);

export default AboutPanel;
