export class fileUtils {
	/* istanbul ignore next */
	// TODO: ignore the coverage report function because requireContext requires an additional babel plugin: https://stackoverflow.com/a/61137440
	static importAll = (requireContext: any): any => {
		let files = {};

		requireContext.keys().forEach((item: string) => {
			//@ts-ignore
			files[item.replace('./', '')] = requireContext(item);
		});

		return files;
	};
}
