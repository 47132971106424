import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './ContactInfoItem.module.scss';

type ContactInfoItemProps = {
	title: string;
	caption: string;
	linkId?: string;
	link: string;
	iconId?: string;
	icon: IconProp;
};

const ContactInfoItem = ({ title, caption, link, linkId, icon, iconId }: ContactInfoItemProps) => (
	<div className={styles.item}>
		<a id={iconId} href={link}>
			<FontAwesomeIcon icon={icon} className={styles.icon} />
		</a>
		<h3 className={styles.title}>{title}</h3>
		<a id={linkId} href={link} className={styles.linkCaption}>
			{caption}
		</a>
	</div>
);

export default ContactInfoItem;
