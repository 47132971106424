import { configureStore } from '@reduxjs/toolkit';

import contactFormReducer from './contactForm/contactFormSlice';
import serverReducer from './server/serverSlice';

export const rootReducer = {
	server: serverReducer,
	contactForm: contactFormReducer
};

export const store = configureStore({
	reducer: rootReducer
});
