import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col } from 'react-bootstrap';

import styles from './ServiceItem.module.scss';

type ServiceItemProps = {
	title: string;
	icon: IconProp;
	animationDelay?: number;
	children: React.ReactNode;
};

const ServiceItem = ({ title, icon, animationDelay = 0, children }: ServiceItemProps) => {
	return (
		<Col md={6} lg={6}>
			<ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={0} delay={animationDelay}>
				<div className={styles.item}>
					<div className={styles.icon}>
						<FontAwesomeIcon icon={icon} />
					</div>
					<h4 className={styles.title}>{title}</h4>
					<p className={styles.description}>{children}</p>
				</div>
			</ScrollAnimation>
		</Col>
	);
};

export default ServiceItem;
