import React from 'react';

import logo from 'src/assets/images/logo.png';

import styles from './HomeLogo.module.scss';

const HomeLogo = () => (
	<div className={styles.logo}>
		<img src={logo} alt="Logo Cymet Solutions" />
	</div>
);

export default HomeLogo;
