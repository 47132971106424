import { technologies } from 'src/shared/constants';
import { CarouselImage } from 'src/shared/models';
import { fileUtils } from 'src/shared/toolbox';

const carouselImageFiles = fileUtils.importAll(
	require.context('src/assets/images/technologies', false, /\.(png|jpe?g|svg)$/)
);

const carouselImages: CarouselImage[] = [
	{
		name: technologies.react,
		image: carouselImageFiles[`${technologies.react}.png`],
		style: { paddingTop: '30px', paddingBottom: '0px', marginLeft: '10px' }
	},
	{
		name: technologies.angular,
		image: carouselImageFiles[`${technologies.angular}.png`],
		style: { paddingTop: '30px', paddingBottom: '0px' }
	},
	{
		name: technologies.android,
		image: carouselImageFiles[`${technologies.android}.png`],
		style: { paddingTop: '20px', paddingBottom: '0px', marginLeft: '4px' }
	},
	{
		name: technologies.ios,
		image: carouselImageFiles[`${technologies.ios}.png`],
		style: { paddingTop: '20px', paddingBottom: '0px' }
	},
	{
		name: technologies.reactNative,
		image: carouselImageFiles[`${technologies.reactNative}.png`],
		style: { marginLeft: '10px' }
	},
	{
		name: technologies.ionic,
		image: carouselImageFiles[`${technologies.ionic}.png`],
		style: { marginLeft: '5px' }
	},
	{
		name: technologies.netCore,
		image: carouselImageFiles[`${technologies.netCore}.png`],
		style: { marginLeft: '10px' }
	},
	{
		name: technologies.springBoot,
		image: carouselImageFiles[`${technologies.springBoot}.png`],
		style: { marginLeft: '10px' }
	},
	{
		name: technologies.nodeJs,
		image: carouselImageFiles[`${technologies.nodeJs}.png`],
		style: { marginLeft: '12px' }
	},
	{
		name: technologies.openshift,
		image: carouselImageFiles[`${technologies.openshift}.png`],
		style: { maxWidth: '95%', paddingTop: '3px', paddingBottom: '0px', marginLeft: '5px' }
	},
	{
		name: technologies.microsoftAzure,
		image: carouselImageFiles[`${technologies.microsoftAzure}.png`],
		style: { maxWidth: '85%', marginLeft: 'auto', marginRight: 'auto', display: 'block' }
	},
	{
		name: technologies.aws,
		image: carouselImageFiles[`${technologies.aws}.png`],
		style: { maxWidth: '90%', marginLeft: '4px' }
	},
	{
		name: technologies.salesforce,
		image: carouselImageFiles[`${technologies.salesforce}.png`],
		style: { maxWidth: '80%', paddingLeft: '15px' }
	},
	{
		name: technologies.sqlServer,
		image: carouselImageFiles[`${technologies.sqlServer}.png`],
		style: { paddingTop: '30px', paddingBottom: '0px', maxWidth: '90%', marginLeft: '10px' }
	},
	{
		name: technologies.mysql,
		image: carouselImageFiles[`${technologies.mysql}.png`],
		style: { paddingTop: '20px', paddingBottom: '0px', marginLeft: '10px' }
	},
	{
		name: technologies.mongoDb,
		image: carouselImageFiles[`${technologies.mongoDb}.png`],
		style: { paddingTop: '30px', paddingBottom: '0px', marginLeft: '10px' }
	}
];

export default carouselImages;
