import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { Button, Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';
import Sticky from 'react-stickynode';

import logo from 'src/assets/images/logo.png';
import { PATHS } from 'src/routes/paths';
import { Section } from 'src/shared/components';
import { propsSmoothScrollLastMenuLinks, propsSmoothScrollMenuLinks } from 'src/shared/constants';

import styles from './Header.module.scss';

const HeaderSection = () => {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	const navigate = useNavigate();
	const path = useLocation().pathname;
	const location = path.split('/')[1];

	const goHome = () => {
		if (location === '') {
			scroll.scrollToTop({
				smooth: propsSmoothScrollLastMenuLinks.smooth,
				duration: propsSmoothScrollLastMenuLinks.duration,
				delay: propsSmoothScrollLastMenuLinks.delay
			});
		} else {
			navigate(PATHS.INDEX);
		}
	};

	return (
		<Section name="header" componentContainer={Fragment} classes={{ section: styles.section }}>
			<Sticky
				enabled={true}
				innerZ={50}
				innerClass={styles.sticky}
				innerActiveClass={styles.stickyActive}
				enableTransforms={false}
			>
				<Navbar expand={'lg'} as="header" expanded={false} className={styles.header}>
					<Container>
						<Navbar.Brand className={styles.navBrand}>
							<img id="home_logo" src={logo} alt="Cymet logo" onClick={goHome} />
						</Navbar.Brand>
						<Navbar.Toggle
							id="responsive_menu_show_button"
							aria-controls="responsive_navbar_nav"
							className={styles.mobileNavToggleButton}
							onClick={handleShow}
						>
							<FontAwesomeIcon icon="bars" className={styles.mobileNavIcon} />
						</Navbar.Toggle>
						<Navbar.Offcanvas
							id="responsive_navbar_nav"
							className={styles.mobileNav}
							backdropClassName={styles.mobileNavBackdrop}
							show={show}
							onHide={handleClose}
						>
							<Offcanvas.Header className={styles.offcanvasHeader}>
								<Button
									id="responsive_menu_close_button"
									onClick={handleClose}
									variant="none"
									className={styles.mobileNavCloseButton}
								>
									<FontAwesomeIcon icon="times" className={styles.mobileNavIcon} />
								</Button>
							</Offcanvas.Header>
							<Offcanvas.Body className={styles.offcanvasBody}>
								<Nav className={cn(styles.navMenu, 'justify-content-end', 'flex-grow-1')}>
									<Link id="home_menu_link" to="home" {...propsSmoothScrollMenuLinks} onClick={handleClose}>
										Inicio
									</Link>
									<Link id="about_menu_link" to="about" {...propsSmoothScrollMenuLinks} onClick={handleClose}>
										Nosotros
									</Link>
									<Link id="services_menu_link" to="services" {...propsSmoothScrollMenuLinks} onClick={handleClose}>
										Servicios
									</Link>
									<Link
										id="technologies_menu_link"
										to="technologies"
										{...propsSmoothScrollLastMenuLinks}
										onClick={handleClose}
									>
										Tecnologías
									</Link>
									<Link id="contact_menu_link" to="contact" {...propsSmoothScrollLastMenuLinks} onClick={handleClose}>
										Contacto
									</Link>
								</Nav>
							</Offcanvas.Body>
						</Navbar.Offcanvas>
					</Container>
				</Navbar>
			</Sticky>
		</Section>
	);
};

export default HeaderSection;
