import { BrowserRouter } from 'react-router-dom';

import 'animate.css';
import './App.scss';

import { AppRoutes } from './routes';
import { fontAwesomeIcons } from './shared/settings';

fontAwesomeIcons.setup();

function App() {
	return (
		<BrowserRouter>
			<AppRoutes />
		</BrowserRouter>
	);
}

export default App;
