import React, { useRef } from 'react';
import { Col } from 'react-bootstrap';
import Carousel from 'react-tiny-slider';

import carouselImages from './technologyCarouselImagesConfig';
import styles from './TechnologiesCarousel.module.scss';

/* istanbul ignore next */
// TODO: fix the problem with the Carousel component because it throws an exception when rendering with data from the tests, for this reason this component is ignored from the coverage report
const TechnologiesCarousel = () => {
	const carousel = useRef(null);

	return (
		<Col lg={12}>
			<Carousel
				data-testid={'tecnologies-carousel'}
				nav={true}
				navPosition={'bottom'}
				controls={false}
				mouseDrag
				items={6}
				loop={true}
				speed={500}
				autoplay={true}
				autoplayButtonOutput={false}
				responsive={{ 0: { items: 3 }, 768: { items: 4 }, 900: { items: 6 } }}
				ref={carousel}
				onClick={(slideIndex, info, event) => {
					//@ts-ignore
					carousel.current.play();
				}}
			>
				{carouselImages.map((carouselImage, index) => {
					return (
						<div key={index} className={styles.slide}>
							<img src={carouselImage.image} style={carouselImage.style} alt={`tecnology ${carouselImage.name}`} />
						</div>
					);
				})}
			</Carousel>
		</Col>
	);
};

export default TechnologiesCarousel;
