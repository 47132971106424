import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';

import styles from './SectionHeader.module.scss';

type SectionHeaderProps = {
	title: string;
	description: string | ReactNode;
	style?: React.CSSProperties;
};

const SectionHeader = ({ title, description, style }: SectionHeaderProps) => {
	return (
		<Row style={style}>
			<Col md={12}>
				<h3 className={styles.title}>{title}</h3>
				<p className={styles.description}>{description}</p>
			</Col>
		</Row>
	);
};

export default SectionHeader;
