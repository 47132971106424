import React from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollAnimation from 'react-animate-on-scroll';

import styles from './AboutPillarItem.module.scss';

type AboutPillarItemProps = {
	title: string;
	icon: IconProp;
	animationDelay?: number;
	children: React.ReactNode;
};

const AboutPillarItem = ({ title, icon, animationDelay = 0, children }: AboutPillarItemProps) => {
	return (
		<ScrollAnimation animateIn="fadeInUp" animateOnce={true} offset={0} delay={animationDelay}>
			<div className={styles.item}>
				<div className={styles.icon}>
					<FontAwesomeIcon icon={icon} />
				</div>
				<h4 className={styles.title}>{title}</h4>
				<p className={styles.text}>{children}</p>
			</div>
		</ScrollAnimation>
	);
};

export default AboutPillarItem;
