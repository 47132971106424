import TagManager from 'react-gtm-module';

const gmtConfig = {
	gtmId: process.env.REACT_APP_GTM_CONTAINER_ID as string
};

export class GTM {
	static initialize = () => {
		TagManager.initialize(gmtConfig);
	};
}
