import React from 'react';
import { Col } from 'react-bootstrap';

import { Section, SectionContent, SectionHeader } from 'src/shared/components';

import AboutLogo from './components/AboutLogo/AboutLogo';
import AboutPanel from './components/AboutPanel/AboutPanel';

const AboutSection = () => {
	return (
		<Section name="about" animation="fadeIn">
			<SectionHeader
				title="Nosotros"
				description="Ayudamos a empresas y clientes a transitar ágilmente su camino a la transformación digital."
			/>
			<SectionContent>
				<Col md={6}>
					<AboutPanel />
				</Col>
				<Col md={6}>
					<AboutLogo />
				</Col>
			</SectionContent>
		</Section>
	);
};

export default AboutSection;
