import React, { useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { Section, SectionContent } from 'src/shared/components';
import { fetchCurrentYear } from 'src/store/server';

import styles from './Footer.module.scss';

const Footer = () => {
	const currentYear = useSelector((state: RootState) => state.server.currentYear);
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(fetchCurrentYear());
	}, [dispatch]);

	return (
		<Section name="footer" componentRoot={'footer'} classes={{ section: styles.section }}>
			<SectionContent>
				<Col md={12}>
					<div className="text-center">
						&copy; {currentYear} <strong>Cymet Solutions</strong>. Todos los derechos reservados
					</div>
				</Col>
			</SectionContent>
		</Section>
	);
};

export default Footer;
