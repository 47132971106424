import React from 'react';
import { Link } from 'react-scroll';

import { propsSmoothScrollLink } from 'src/shared/constants';

import styles from './ButtonLinkOutstanding.module.scss';

type ButtonLinkOutstandingProps = {
	id?: string;
	href?: string;
	variant?: variant;
	children: React.ReactNode;
};

type variant = 'contained' | 'outlined';

const ButtonLinkOutstanding = ({ id, variant = 'contained', href = '#', children }: ButtonLinkOutstandingProps) => {
	const extraClass = variant === 'contained' ? 'btnFilled' : 'btnUnfilled';

	return (
		<Link
			id={id}
			to={href.replace('#', '')}
			role="button"
			className={`${styles.btnLink} ${styles[extraClass]}`}
			{...propsSmoothScrollLink}
		>
			{children}
		</Link>
	);
};

export default ButtonLinkOutstanding;
