import React, { Fragment } from 'react';
import cn from 'classnames';
import ScrollAnimation from 'react-animate-on-scroll';
import { Container } from 'react-bootstrap';

import styles from './Section.module.scss';

type SectionProps = {
	componentRoot?: React.ElementType;
	name: string;
	animation?: string;
	style?: React.CSSProperties;
	classes?: SectionClassesAttributes;
	componentContainer?: React.ElementType;
	children: React.ReactNode;
};

type SectionClassesAttributes = {
	section?: string;
	container?: string;
};

const Section = ({
	componentRoot = 'section',
	name,
	animation,
	style,
	classes,
	componentContainer = Container,
	children
}: SectionProps) => {
	const ComponentRoot = componentRoot as React.ElementType;
	const ComponentContainer = componentContainer as React.ElementType;
	const componentContainerProps = componentContainer === Fragment ? null : { className: classes?.container };
	const ComponetScrollAnimation = animation ? ScrollAnimation : Fragment;
	const componetScrollAnimationProps =
		ComponetScrollAnimation === Fragment ? null : { animateIn: animation, animateOnce: true, offset: 0 };

	return (
		<ComponentRoot
			id={name}
			data-testid={`${name}-section`}
			className={cn(styles.section, classes?.section)}
			style={style}
		>
			<ComponentContainer {...componentContainerProps}>
				<ComponetScrollAnimation {...componetScrollAnimationProps}>{children}</ComponetScrollAnimation>
			</ComponentContainer>
		</ComponentRoot>
	);
};

export default Section;
