import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import { BackToTopFloatButton, Footer, Header, Preloader } from './components';

type MainLayoutProps = {
	homeComponent?: React.ElementType;
	children: React.ReactNode;
};

const MainLayout = ({ homeComponent, children }: MainLayoutProps) => {
	const HomeComponent = homeComponent as React.ElementType;
	const [showPreloader, setShowPreloader] = useState(true);

	useEffect(() => {
		setTimeout(() => setShowPreloader(false), 1000);
	}, []);

	return (
		<Container fluid>
			{homeComponent && <HomeComponent />}
			<Header />
			{children}
			<Footer />
			<Preloader show={showPreloader} />
			<BackToTopFloatButton />
		</Container>
	);
};

export default MainLayout;
