import React, { Fragment } from 'react';

import { Morphext } from 'src/shared/components';

import styles from './HomeText.module.scss';

const HomeText = () => {
	const solutionsPhrases = ['de software', 'innovadoras', 'eficientes', 'inteligentes'];

	return (
		<Fragment>
			<h1 className={styles.title}>BIENVENIDO</h1>
			<h2 className={styles.text}>
				Nosotros creamos{' '}
				<span className={styles.rotatingText}>
					soluciones <Morphext animation="flipInX" speed={3000} phrases={solutionsPhrases} />
				</span>{' '}
				para fortalecer e impulsar tu negocio
			</h2>
		</Fragment>
	);
};

export default HomeText;
