export enum technologies {
	react = 'reactjs',
	angular = 'angular',
	android = 'android',
	ios = 'ios',
	reactNative = 'react-native',
	ionic = 'ionic',
	netCore = 'net-core',
	springBoot = 'spring-boot',
	nodeJs = 'node-js',
	openshift = 'openshift',
	microsoftAzure = 'microsoft-azure',
	aws = 'aws',
	salesforce = 'salesforce',
	sqlServer = 'sql-server',
	mysql = 'mysql',
	mongoDb = 'mongo-db'
}

export const propsSmoothScrollLink = {
	smooth: 'easeInOutQuart',
	spy: true,
	offset: -72,
	duration: 500,
	delay: 0,
	activeClass: 'active'
};

export const propsSmoothScrollMenuLinks = Object.assign({ ...propsSmoothScrollLink }, { className: 'nav-link' });

export const propsSmoothScrollLastMenuLinks = Object.assign({ ...propsSmoothScrollMenuLinks }, { offset: -60 });

export const GTM_EVENTS = {
	CONTACT_FORM_SUBMIT: 'contact_form_submit',
	CONTACT_FORM_SUBMIT_SUCCESSFUL: 'contact_form_submit_successful',
	CONTACT_FORM_SUBMIT_FAILED: 'contact_form_submit_failed'
};
