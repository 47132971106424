import React from 'react';

import { Section, SectionContent, SectionHeader } from 'src/shared/components';

import ServiceList from './components/ServiceList/ServiceList';
import styles from './ServicesSection.module.scss';

const ServicesSection = () => (
	<Section name="services" animation="fadeIn" classes={{ section: styles.section }}>
		<SectionHeader
			title="Servicios"
			description="Ofrecemos soluciones de software personalizadas desarrolladas con alta calidad, innovación y los mejores estándares del mercado."
		/>
		<SectionContent>
			<ServiceList />
		</SectionContent>
	</Section>
);

export default ServicesSection;
