import React, { ReactNode } from 'react';
import { Animated } from 'react-animated-css';
import { Alert } from 'react-bootstrap';

type ContactFormAlertProps = {
	variant: 'success' | 'danger';
	title: string;
	body: string;
	footer: string | ReactNode;
	show: boolean;
	onClose: (a: any, b: any) => void;
};

const ContactFormAlert = ({ title, body, footer, variant, show, onClose }: ContactFormAlertProps) => (
	<Animated animationIn="fadeIn" animationInDuration={3000} animationOut="fadeOut" isVisible={show}>
		<Alert variant={variant} style={{ textAlign: 'center' }} onClose={onClose} show={show} dismissible>
			<Alert.Heading>{title}</Alert.Heading>
			<p>{body}</p>
			<hr />
			<p className="mb-0">{footer}</p>
		</Alert>
	</Animated>
);

export default ContactFormAlert;
