import React from 'react';
import { Animated } from 'react-animated-css';

import styles from './Preloader.module.scss';

type PreloaderProps = {
	show?: boolean;
};

const Preloader = ({ show = true }: PreloaderProps) => (
	<Animated
		animationIn="fadeIn"
		animationInDuration={0}
		animationOut="fadeOut"
		animationOutDuration={500}
		isVisible={show}
		className={styles.container}
	>
		<div className={styles.preloader}></div>
	</Animated>
);

export default Preloader;
