import React from 'react';
import { configureStore } from '@reduxjs/toolkit';
import { render as rtlRender } from '@testing-library/react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import configureMockStore from 'redux-mock-store';
import thunk from 'redux-thunk';

import { rootReducer } from 'src/store/configureStore';

const middlewares = [thunk];
const mockStore = configureMockStore(middlewares);

export class testUtils {
	static renderComponentWithStore = (
		ui: any,
		{
			// @ts-ignore
			preloadedState = undefined,
			store = configureStore({ reducer: rootReducer, preloadedState }),
			...renderOptions
		} = {}
	) => {
		const Wrapper = ({ children }: { children: React.ReactNode }) => (
			<Provider store={store}>
				<BrowserRouter>{children}</BrowserRouter>
			</Provider>
		);

		return rtlRender(ui, { wrapper: Wrapper, ...renderOptions });
	};

	// create a silly store that can never be changed
	static createMockStore = (storeData: any) => {
		// FIXME: const store: Store<RootState, AnyAction> = mockStore(storeData); -> generate error type
		const store = mockStore(storeData);
		return store;
	};
}
