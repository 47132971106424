import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import styles from './ContactFormButtonLoading.module.scss';

type ContactFormButtonLoadingProps = {
	id?: string;
	variant: string;
	textLoading: string;
	isLoading: boolean;
	children: React.ReactNode;
};

const ContactFormButtonLoading = ({ id, variant, textLoading, isLoading, children }: ContactFormButtonLoadingProps) => (
	<Button id={id} variant={variant} type="submit">
		{isLoading ? (
			<>
				<Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className={styles.spinner} />
				{textLoading}
			</>
		) : (
			children
		)}
	</Button>
);

export default ContactFormButtonLoading;
