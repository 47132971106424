import React, { Fragment } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Row } from 'react-bootstrap';

type SectionContentProps = {
	componentRoot?: React.ElementType;
	style?: React.CSSProperties;
	animation?: string;
	children: React.ReactNode;
};

const SectionContent = ({ componentRoot = Row, animation, children, ...restProps }: SectionContentProps) => {
	const ComponentRoot = componentRoot as React.ElementType;
	const componentProps = componentRoot === Fragment ? null : restProps;
	const ComponetScrollAnimation = animation ? ScrollAnimation : Fragment;
	const componetScrollAnimationProps =
		ComponetScrollAnimation === Fragment ? null : { animateIn: animation, animateOnce: true, offset: 0 };

	return (
		<ComponentRoot {...componentProps}>
			<ComponetScrollAnimation {...componetScrollAnimationProps}>{children}</ComponetScrollAnimation>
		</ComponentRoot>
	);
};

export default SectionContent;
