import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Index, Services } from 'src/pages';

import { PATHS } from './paths';

const AppRoutes = () => (
	<Routes>
		<Route path={PATHS.INDEX} element={<Index />} />
		<Route path={PATHS.SERVICES} element={<Services />} />
		<Route path="*" element={<Navigate replace to="/" />} />
	</Routes>
);

export default AppRoutes;
