import React, { Fragment } from 'react';

import ServiceItem from './components/ServiceItem/ServiceItem';

const ServiceList = () => (
	<Fragment>
		<ServiceItem title="Desarrollo de Sitios & Apps Web" icon="display" animationDelay={200}>
			Diseñamos y construimos <b>aplicaciones web atractivas, de alto rendimiento y totalmente personalizadas</b> para
			atender cualquier área operativa de su negocio.
			<br />
			<br />
			Además de <b>sitios y apps web</b> convencionales para ordenadores PC y portátiles, también ofrecemos desarrollo
			de <b>apps web responsivas</b> y <b>apps web progresivas (PWA)</b> optimizadas para dispositivos móviles
			smartphones y tablets con el fin de brindar la mejor experiencia web de acuerdo a sus necesidades, tiempo y
			presupuesto.
		</ServiceItem>
		<ServiceItem title="Desarrollo de Apps Móviles" icon="mobile-screen-button" animationDelay={400}>
			Desarrollamos <b>aplicaciones móviles robustas, innovadoras y seguras</b> que le permitirán explotar el potencial
			de los dispositivos móviles modernos para expandir el ratio de alcance y mejorar la eficiencia de sus procesos de
			negocios.
			<br />
			<br />
			En base a la visión integral de tus necesidades te ofrecemos el desarrollo de <b>apps móviles nativas</b> de alto
			rendimiento para iOS y Android o <b>apps móviles híbridas</b> basadas en frameworks web compatibles con múltiples
			sistemas operativos a un menor costo y esfuerzo.{' '}
		</ServiceItem>
		<ServiceItem title="Consultoría de Desarrollo & Arquitectura de Software" icon="file-code" animationDelay={200}>
			Brindamos <b>servicios de consultoría IT</b> para orientarlos y acompañarlos en el diseño y desarrollo de
			soluciones digitales end-to-end, podemos asesorarte en cualquier asunto relacionado con el ciclo de desarrollo de
			un software nuevo o existente.
			<br />
			<br />
			Generamos entregables de <b>diseños arquitectónicos, especificaciones funcionales, planes estratégicos</b> y
			también ofrecemos <b>capacitaciones técnicas</b> para maximizar el rendimiento y la productividad de su empresa.
			<br />
			<br />
		</ServiceItem>
		<ServiceItem title="Consultoría de Gestión de Proyectos Ágiles" icon="chart-simple" animationDelay={400}>
			Ofrecemos <b>servicios de coaching, mentoring y capacitaciones</b> para ayudarlos en la transición hacia la
			adopción de una cultura ágil en su organización.
			<br />
			<br />
			Podemos asesorarlos en la <b>implementación de los frameworks ágiles</b> más adoptados y reconocidos actualmente
			en la gestión de proyectos, tales como <b>Scrum y Kanban</b>, y los acompañamos durante la ejecución de los mismos
			para garantizar la <b>aplicación de los principios y prácticas ágiles</b> que mejor se adapten a sus equipos para
			maximizar su productividad, creatividad y motivación.
		</ServiceItem>
	</Fragment>
);

export default ServiceList;
